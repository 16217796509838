import React, { useEffect, useState } from 'react';
import "wowjs";
import $ from 'jquery';

const Works = () => {
  const ProjectsData = [
    {
      img: "images/projects/10.png",
      title: "LeloTrip",
      para: "Welcome to Lelotrip Tour & Travels, your premier B2B travel portal dedicated to transforming the way businesses navigate the world of travel.",
      link: "https://lelotrip.com/",
      alt: "LeloTrip website screenshot",
      category: "ReactJs"
    },
    {
      img: "images/projects/11.png",
      title: "LeloTrip Admin",
      para: "Welcome to Lelotrip Tour & Travels, your premier B2B travel portal dedicated to transforming the way businesses navigate the world of travel.",
      link: "https://admin.lelotrip.com/",
      alt: "LeloTrip Admin dashboard screenshot",
      category: "Laravel"
    },
    {
      img: "images/projects/1.jpeg",
      title: "V6co",
      para: "Innovative Home Wellness & Manufacturing",
      link: "https://v6co.com/",
      alt: "V6co homepage screenshot",
      category: "PHP"
    },
    {
      img: "images/projects/2.png",
      title: "Ibogaine Healing Center",
      para: "A clinical and spiritual approach to Ibogaine treatment.",
      link: "",
      alt: "Ibogaine Healing Center homepage screenshot",
      category: "WordPress"
    },
    {
      img: "images/projects/3.png",
      title: "American Laboratory Trading",
      para: "All Equipment Tested & Refurbished Prior to Shipment",
      link: "https://americanlaboratorytrading.com/",
      alt: "American Laboratory Trading website screenshot",
      category: "PHP"
    },
    {
      img: "images/projects/4.png",
      title: "Yatri Service",
      para: "Welcome to Yatri Service, your gateway to seamless and efficient travel management.",
      link: "https://yatriservice.com/",
      alt: "Yatri Service website screenshot",
      category: "ReactJs"
    },
    {
      img: "images/projects/5.png",
      title: "Yatri Service Admin",
      para: "Welcome to Yatri Service, your gateway to seamless and efficient travel management.",
      link: "https://admin.yatriservice.com/",
      alt: "Yatri Service Admin dashboard screenshot",
      category: "Laravel"
    },
    {
      img: "images/projects/6.png",
      title: "Abasinexpress",
      para: "Our B2B portal is designed to elevate your travel experience, providing a user-friendly platform for travel professionals.",
      link: "http://flyabasin.com/",
      alt: "Abasinexpress website screenshot",
      category: "ReactJs"
    },
    {
      img: "images/projects/7.png",
      title: "Abasinexpress Admin",
      para: "Our B2B portal is designed to elevate your travel experience, providing a user-friendly platform for travel professionals.",
      link: "http://admin.flyabasin.com/",
      alt: "Abasinexpress Admin dashboard screenshot",
      category: "Laravel"
    },
    {
      img: "images/projects/8.png",
      title: "BookurTicket",
      para: "At BookurTicket, we believe in transforming your travel dreams into reality.",
      link: "https://bookurticket.com/",
      alt: "BookurTicket website screenshot",
      category: "Laravel"
    },
    {
      img: "images/projects/9.png",
      title: "BookurTicket Admin",
      para: "At BookurTicket, we believe in transforming your travel dreams into reality.",
      link: "https://bookurticket.com/admin",
      alt: "BookurTicket Admin dashboard screenshot",
      category: "Laravel"
    }
  ];

  const [active, setActive] = useState(1);

  useEffect(() => {
    function filterAnimation() {
      const activeBg = $(".portfolio-filter .button-group .active-bg");
      const element = $(".portfolio-filter .button-group .active");
      $(".portfolio-filter .button-group button").on("click", function () {
        const e = $(this);
        activeFilterBtn(activeBg, e);
      });
      activeFilterBtn(activeBg, element);
    }
    filterAnimation();

    function activeFilterBtn(activeBg, e) {
      if (!e.length) return;
      const leftOff = e.offset().left;
      const width = e.outerWidth();
      const menuLeft = $(".portfolio-filter .button-group").offset().left;
      e.siblings().removeClass("active");
      e.closest("button")
        .siblings()
        .addClass("active");
      activeBg.css({ left: leftOff - menuLeft + "px", width: width + "px" });
    }
  }, []);

  const filteredProjects = ProjectsData.filter(project => {
    switch (active) {
      case 1: return true; // Show all projects
      case 2: return project.category === "WordPress";
      case 3: return project.category === "PHP";
      case 4: return project.category === "Laravel";
      case 5: return project.category === "ReactJs";
      default: return true;
    }
  });

  return (
    <>
      {/* PORTFOLIO SECTION START */}
      <section className="portfolio-section" id="works-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header text-center">
                <h4 className="section-title wow fadeInUp" data-wow-delay=".3s">
                  My Recent Works
                </h4>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  We transform your ideas into unique web projects that inspire both you and your customers.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className="portfolio-filter text-center wow fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="button-group filter-button-group">
                  <button
                    data-filter="*"
                    className={active === 1 ? "active" : ""}
                    onClick={() => setActive(1)}
                  >
                    All
                  </button>
                  <button
                    data-filter=".WordPress"
                    className={active === 2 ? "active" : ""}
                    onClick={() => setActive(2)}
                  >
                    WordPress
                  </button>
                  <button
                    data-filter=".PHP"
                    className={active === 3 ? "active" : ""}
                    onClick={() => setActive(3)}
                  >
                    PHP
                  </button>
                  <button
                    data-filter=".Laravel"
                    className={active === 4 ? "active" : ""}
                    onClick={() => setActive(4)}
                  >
                    Laravel
                  </button>
                  <button
                    data-filter=".ReactJs"
                    className={active === 5 ? "active" : ""}
                    onClick={() => setActive(5)}
                  >
                    ReactJs
                  </button>
                  <div className="active-bg" />
                </div>
              </div>
              <div className="portfolio-box wow fadeInUp" data-wow-delay=".6s">
                <div className="portfolio-sizer" />
                <div className="gutter-sizer" />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {filteredProjects.map((project, index) => (
                    <div className={`portfolio-item ${project.category}`} key={index}>
                      <div className="image-box">
                        <img src={project.img} alt={project.alt} />
                      </div>
                      <div className="content-box">
                        <h3 className="portfolio-title">{project.title}</h3>
                        <p>{project.para}</p>
                        <i className="flaticon-up-right-arrow" />
                        <a href={project.link} target='_blank' aria-label={`View project: ${project.title}`}>
                          <button
                            data-mfp-src="#portfolio-wrapper"
                            className="portfolio-link modal-popup"
                          >
                          </button>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* PORTFOLIO SECTION END */}
    </>
  );
}

export default Works;
