import React, { useEffect, useState } from 'react';
import { WOW } from 'wowjs';
import { gsap } from 'gsap';
import Counters from '../Counters';

const Banner = () => {

    useEffect(() => {
        // Initialize WOW.js
        const wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 100,
            mobile: true,
            live: true,
        });
        wow.init();

        // GSAP Preloader Animation
        const svg = document.getElementById('preloaderSvg');
        const svgText = document.querySelector('.hero-section .intro_text svg text');
        const tl = gsap.timeline({
            onComplete: startStrokeAnimation,
        });

        const curve = 'M0 502S175 272 500 272s500 230 500 230V0H0Z';
        const flat = 'M0 2S175 1 500 1s500 1 500 1V0H0Z';

        tl.to('.preloader-heading .load-text , .preloader-heading .cont', {
            delay: 1.5,
            y: -100,
            opacity: 0,
        });
        tl.to(svg, {
            duration: 0.5,
            attr: { d: curve },
            ease: 'power2.easeIn',
        }).to(svg, {
            duration: 0.5,
            attr: { d: flat },
            ease: 'power2.easeOut',
        });
        tl.to('.preloader', {
            y: -1500,
        });
        tl.to('.preloader', {
            zIndex: -1,
            display: 'none',
        });

        function startStrokeAnimation() {
            if (svgText) {
                svgText.classList.add('animate-stroke');
            }
        }
    }, []);

    return (
        <>
            {/* HERO SECTION START */}
            <section className="hero-section d-flex align-items-center" id="intro">
                <div className="intro_text">
                    <svg viewBox="0 0 1320 300">
                        <text x="50%" y="50%" textAnchor="middle" aria-label="Welcome">
                            HI
                        </text>
                    </svg>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="hero-content-box">
                                <span className="hero-sub-title">Hello, I’m Harsh Saini</span>
                                <h1 className="hero-title">
                                    Full Stack Web <br />
                                    Developer & UX Designer
                                </h1>
                                <div className="hero-image-box d-md-none text-center">
                                    <img src="images/me.png" alt="Harsh Saini - Full Stack Web Developer" />
                                </div>
                                <p className="lead">
                                    I specialize in transforming complex user experience challenges into effective, integrity-driven web solutions that engage and resonate with users.
                                </p>
                                <div className="button-box d-flex flex-wrap align-items-center">
                                    <a href="path-to-your-cv.pdf" className="btn tj-btn-secondary" download>
                                        Download CV <i className="flaticon-download" />
                                    </a>
                                    <ul className="ul-reset social-icons">
                                        <li>
                                            <a href="https://www.instagram.com/harshsainir15/" aria-label="Harsh Saini on Instagram">
                                                <i className="fa-brands fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" aria-label="Harsh Saini's Basketball Profile">
                                                <i className="fa-light fa-basketball" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/in/harsh-saini-7881b0325/" aria-label="Harsh Saini on LinkedIn">
                                                <i className="fa-brands fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" aria-label="Harsh Saini on GitHub">
                                                <i className="fa-brands fa-github" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block">
                            <div className="hero-image-box text-center">
                                <img src="images/me.png" alt="Harsh Saini - Full Stack Web Developer" />
                            </div>
                        </div>
                    </div>
                    <Counters />
                </div>
            </section>

            {/* HERO SECTION END */}
        </>
    );
};

export default Banner;
