import React from 'react'
import Banner from '../Banner'
import Contact from '../Contact'
import { Helmet } from 'react-helmet';

const Contacts = () => {
    return (
        <>
            <Helmet>
                <title>Contact - Harsh Saini</title>
                <meta name="description" content="Get in touch with Harsh Saini for web development inquiries or project discussions." />
                <meta name="keywords" content="Contact, Harsh Saini, Web Development Inquiries" />

                {/* Open Graph */}
                <meta property="og:title" content="Contact - Harsh Saini" />
                <meta property="og:description" content="Get in touch with Harsh Saini for web development inquiries or project discussions." />
                <meta property="og:image" content="https://iamharsh.org/path-to-your-og-image.png" />
                <meta property="og:url" content="https://iamharsh.org/contact" />
                <meta property="og:type" content="website" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact - Harsh Saini" />
                <meta name="twitter:description" content="Get in touch with Harsh Saini for web development inquiries or project discussions." />
                <meta name="twitter:image" content="https://iamharsh.org/path-to-your-twitter-image.png" />

                {/* Structured Data */}
                <script type="application/ld+json">
                    {`
          {
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact - Harsh Saini",
            "description": "Get in touch with Harsh Saini for web development inquiries.",
            "url": "https://iamharsh.org/contact"
          }
        `}
                </script>
            </Helmet>
            <main class="site-content" id="content">
                <Banner heading="Contact" />
                <Contact />

            </main>
        </>
    )
}

export default Contacts