import React, { useEffect, useState } from 'react';
import './App.css';
import "./css/animate.min.css";
import "./css/bootstrap.min.css";
import "./css/font-awesome-pro.min.css";
import "./css/flaticon_gerold.css";
import "./css/nice-select.css";
import "./css/backToTop.css";
import "./css/owl.carousel.min.css";
import "./css/swiper.min.css";
import "./css/odometer-theme-default.css";
import "./css/magnific-popup.css";
import "./css/main.css";
import "./css/main-2.css";
import "./css/main-3.css";
import "./css/light-mode.css";
import "./css/meanmenu.css";
import "./css/responsive.css";
import "./css/responsive-2.css";
import "./css/responsive-3.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './components/pages/home/Home';
import About from './components/pages/about/About';
import Services from './components/pages/services/Services';
import Portfolio from './components/pages/portfolio/Portfolio';
import Contacts from './components/pages/contact/Contacts';
import Blog from './components/pages/blog/Blog';
import BlogDetail from './components/pages/blog/BlogDetail';
import { WOW } from 'wowjs';
import { gsap } from 'gsap';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const ExpireneData = [
    {
      year: "2024 - Present",
      post: "Full Stack Web Developer",
      location: "Eweblink, Dwarka Delhi",
    },
    {
      year: "2022 - 2023",
      post: "Php Web Developer",
      location: "Jms, Noida Up",
    },
    {
      year: "2021 - 2022",
      post: "Freelancer",
      location: "DesignCrowd, Online Website",
    },
    {
      year: "2020 - 2021",
      post: "Photoshop Designer",
      location: "Online, Delhi",
    }
  ];
  const EducationData = [
    {
      year: "2020 - 2022",
      post: "Web Master Courses",
      location: "Admec Multimedia Insitute, Rohini Delhi",
    },
    {
      year: "2020 - 2024",
      post: "Bachelar of Arts",
      location: "Delhi University, Delhi",
    },
    {
      year: "2019 - 2020",
      post: "Cbse 12th Board",
      location: "Central Board of Secondary Education, Delhi",
    },
    {
      year: "2020 - 2021",
      post: "Cbse 10th Board",
      location: "Central Board of Secondary Education, Delhi",
    }
  ]

  useEffect(() => {
    // Initialize WOW.js
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 100,
      mobile: true,
      live: true,
    });
    wow.init();

    // GSAP Preloader Animation
    const svg = document.getElementById('preloaderSvg');
    const svgText = document.querySelector('.hero-section .intro_text svg text');
    const tl = gsap.timeline({
      onComplete: startStrokeAnimation,
    });

    const curve = 'M0 502S175 272 500 272s500 230 500 230V0H0Z';
    const flat = 'M0 2S175 1 500 1s500 1 500 1V0H0Z';

    tl.to('.preloader-heading .load-text , .preloader-heading .cont', {
      delay: 1.5,
      y: -100,
      opacity: 0,
    });
    tl.to(svg, {
      duration: 0.5,
      attr: { d: curve },
      ease: 'power2.easeIn',
    }).to(svg, {
      duration: 0.5,
      attr: { d: flat },
      ease: 'power2.easeOut',
    });
    tl.to('.preloader', {
      y: -1500,
    });
    tl.to('.preloader', {
      zIndex: -1,
      display: 'none',
    });

    function startStrokeAnimation() {
      if (svgText) {
        svgText.classList.add('animate-stroke');
      }
    }
  }, []);
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home ExpireneData={ExpireneData} EducationData={EducationData} />} />
        <Route path='/about' element={<About ExpireneData={ExpireneData} EducationData={EducationData} />} />
        <Route path='/services' element={<Services />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/contact' element={<Contacts />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog-detail' element={<BlogDetail />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;