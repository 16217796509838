import React, { useEffect, useState } from 'react';
import Banner from '../Banner'
import Service from './Service'
import { Helmet } from 'react-helmet';

const Services = () => {

    return (
        <>
            <Helmet>
                <title>Services - Harsh Saini</title>
                <meta name="description" content="Explore the range of services offered by Harsh Saini, including full stack web development and consulting." />
                <meta name="keywords" content="Services, Harsh Saini, Web Development, Consulting" />

                {/* Open Graph */}
                <meta property="og:title" content="Services - Harsh Saini" />
                <meta property="og:description" content="Discover the web development and consulting services offered by Harsh Saini." />
                <meta property="og:image" content="https://iamharsh.org/path-to-your-og-image.png" />
                <meta property="og:url" content="https://iamharsh.org/services" />
                <meta property="og:type" content="website" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Services - Harsh Saini" />
                <meta name="twitter:description" content="Discover the web development and consulting services offered by Harsh Saini." />
                <meta name="twitter:image" content="https://iamharsh.org/path-to-your-twitter-image.png" />

                {/* Structured Data */}
                <script type="application/ld+json">
                    {`
          {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Web Development",
            "provider": {
              "@type": "Person",
              "name": "Harsh Saini"
            },
            "url": "https://iamharsh.org/services"
          }
        `}
                </script>
            </Helmet>
            <main class="site-content" id="content">
                <Banner heading="Services" />
                <Service />
            </main>
        </>
    )
}

export default Services