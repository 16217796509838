import React from 'react'
import Banner from './Banner'
import Services from './Services'
import Works from './Works'
import Expirence from './Expirence'
import Skills from './Skills'

import Blogs from './Blogs'
import Contact from '../Contact'
import Testimonial from './Testimonial'
import { Helmet } from 'react-helmet';


const Home = ({ ExpireneData, EducationData }) => {
  return (
    <>
      <Helmet>
        <title>Home - Harsh Saini</title>
        <meta name="description" content="Welcome to Harsh Saini's home page, showcasing full stack web development expertise and innovative solutions." />
        <meta name="keywords" content="Harsh Saini, Full Stack Developer, Home Page, Web Development" />

        {/* Open Graph */}
        <meta property="og:title" content="Home - Harsh Saini" />
        <meta property="og:description" content="Explore the home page of Harsh Saini's portfolio featuring development projects and expertise." />
        <meta property="og:image" content="https://iamharsh.org/path-to-your-og-image.png" />
        <meta property="og:url" content="https://iamharsh.org/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home - Harsh Saini" />
        <meta name="twitter:description" content="Explore the home page of Harsh Saini's portfolio featuring development projects and expertise." />
        <meta name="twitter:image" content="https://iamharsh.org/path-to-your-twitter-image.png" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Home - Harsh Saini",
            "description": "Explore the home page of Harsh Saini's portfolio showcasing development projects.",
            "url": "https://iamharsh.org/"
          }
        `}
        </script>
      </Helmet>
      <main class="site-content" id="content">
        <Banner />
        <Skills colorstyle="" />
        {/* <Services /> */}
        <Expirence ExpireneData={ExpireneData} EducationData={EducationData} />
        <Works />
        {/* <Testimonial/> */}
        {/* <Blogs /> */}
        <Contact />
      </main>
    </>
  )
}

export default Home